import React from "react"
import Layout from "../../components/layout";
import Promo from "../../components/promo";
import Feature from "../../components/feature";
import Title from "../../components/title";
import { Helmet } from 'react-helmet';
import { Link } from "gatsby";

import Targets from "../../../static/icons/targets.inline.svg";
import Holiday from "../../../static/icons/holiday.inline.svg";
import Forecast from "../../../static/icons/forecast.inline.svg";
import estimates from "../../../static/product/estimates.svg";
import Arrow from "../../../static/icons/arrow.inline.svg";

const Product = ({ location }) => (
    
    <Layout 
      path={location.pathname}>

      <Helmet
        bodyAttributes={{
          class: 'bg-root text-white'
        }} />
      
      <Title
        sub="Forecast"
        colour="teal-500"
        title="Get an instant snapshot of your finances."
        width="max-w-xs md:max-w-xl">

        <p className="max-w-xl leading-relaxed mx-auto my-8 text-light text-center text-lg md:text-xl">
          You became a freelance to get a better work life balance. To help you do this you need a financial goal,
          and a way to track your progress. Slate was built exactly for this, without the need for 
          complex spreadsheets and calendars.</p>

        <Link to="https://app.heyslate.com/register" className="max-w-xl mx-auto flex items-center justify-center space-x-2 text-accent md:text-lg mt-6">
          <span clasName="text-accent">Get started for free</span>
          <Arrow/>
        </Link>

      </Title>

      <div className="lg:container max-w-6xl mx-auto md:text-lg">

        <div className="mac">

          <div className="relative pb-16/9 rounded-lg overflow-hidden">
            <video className="outline-none absolute w-full h-full object-cover" autoPlay muted loop playsinline>
              <source src="/videos/feature_forecast.mp4" type="video/mp4" />
            </video>
          </div>

        </div>

        <div className="lg:flex lg:space-x-8">

          <div className="lg:w-1/2 space-y-4 sm:space-y-8">

            <Feature 
              icon={<Forecast />}
              title="A peak into the future."
              text="Slate predicts how much you're on track to earn by the end of year, giving you the knowledge to decide what 
              to spend your time on for the rest of the year, whether that's client work or side projects."
              tight={true}
              image={estimates}
              colour="teal-500" />

          </div>

          <div className="lg:w-1/2 space-y-4 sm:space-y-8">

            <Feature 
              icon={<Holiday />}
              title="Time off without the worry."
              text="Slate gives you a real-time view of how much you've worked, and how much more you need to work to meet your 
              financial target. Knowing if you can afford to book time off has never been easier."
              tight={false}
              colour="teal-500" />

            <Feature 
              icon={<Targets />}
              title="Monthly revenue targets."
              text="Some months you'll earn more than others, that's just how freelancing is. Slate makes it easy to see 
              what you need to earn in the future so you don't miss your financial target for the year."
              tight={false}
              colour="teal-500" />

          </div>
          
        </div>

      </div>

      <Promo theme="dark" />

    </Layout>
)

export default Product;